// eslint-disable-next-line import/no-extraneous-dependencies
import $ from 'jquery';

export default function goBackLogic() {
  $('.commsCheckContainer').show();
  $('#contactDetails').show();
  $('.infoContainer').hide();
  $('.informationPanel').empty();
  $('.informationPanelResultsButtons').empty();
  $('.error-result-inputs').hide();
  const mpxn = $('.mpxnInput').val();
  const msn = $('.msnInput').val();
  const chf = $('.chfInput').val();
  $('.mpxnInput').val(mpxn);
  $('.msnInput').val(msn);
  $('.chfInput').val(chf);
}
