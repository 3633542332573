import React from 'react';
import { logIn } from '../session';

export default function ChatPage() {
  return (
    <div id="loginSection">
      <div className="loginPanel">
        <div className="wrap-login100">
          <div className="container-login100-form-btn">
            <button id="loginBtn" className="login100-form-btn" value="Log In" onClick={ logIn }>Log In</button>
          </div>
        </div>
      </div>
    </div>
  );
}
