import React from 'react';
import CommsCheck from '../comms-check/CommsCheck';
import Chat from '../chat/Chat';

export default function HomePage() {
  return (
    <>
      <Chat />
      <CommsCheck />
    </>
  );
}
