// eslint-disable-next-line import/no-extraneous-dependencies
import $ from 'jquery';
import goBackLogic from '../utils';

const DEFAULT_CHAT_REASON_LEVEL = '3';

function getParamsFromURL() {
  const urlSearchParams = new URLSearchParams(window.location.search);
  return Object.fromEntries(urlSearchParams.entries());
}

function getParamFromURL(paramName) {
  const params = getParamsFromURL();
  return params[paramName];
}

function getStateFromURL() {
  const params = getParamsFromURL();

  let state = null;

  if (!params.state) return;

  try {
    state = JSON.parse(atob(params.state));
  } catch (err) {
    console.error(`Tried to decode state var from cognito but failed. The decode threw: ${err}. Now will continue initialising without it.`);
    return;
  }

  delete params.state;
  const newParams = { ...params, ...state };
  window.history.pushState({}, '', `index.html?${(new URLSearchParams(newParams)).toString()}`);
}

window.onbeforeunload = () => 'Reload?';

let accessToken;
let tempAccessToken = '';
let userName;
let emailDomain;
let sessionuserName;
let sessionEmailDomain;
let sessionaccessToken;

$(document).ready(() => {
  sessionuserName = localStorage.getItem('userName');
  sessionaccessToken = localStorage.getItem('accessToken');
  window.chatSession = '';
  // eslint-disable-next-line no-undef
  connect.ChatInterface.init({
    containerId: 'root',
    headerConfig: {
      isHTML: true,
      render: () => (`
                <div class="header-wrapper" style="display: none;">
                <div style="display=flex;">
                <div>
                    <h2 class="welcome-text">Metering Installation Support</h2>
                    <p id="chatDescription"></p>
                </div>
                `),
    },
  });
});

function getToken(tokenVal) {
  sessionaccessToken = localStorage.getItem('accessToken');

  if (sessionaccessToken == null) {
    const testData = {
      grant_type: 'authorization_code',
      client_id: process.env.REACT_APP_AWS_COGNITO_CLIENT_ID,
      redirect_uri: process.env.REACT_APP_AWS_COGNITO_REDIRECT_URI,
      code: tokenVal,
    };
    const dataEncoded = $.param(testData);
    $.ajax({
      type: 'POST',
      url: `${process.env.REACT_APP_AWS_COGNITO_INSTANCE_URL}/oauth2/token`,
      contentType: 'application/x-www-form-urlencoded; charset=utf-8',
      data: dataEncoded,
      success(data) {
        const token = data.id_token;
        accessToken = data.access_token;
        tempAccessToken = accessToken;
        localStorage.setItem('accessToken', tempAccessToken);
        parseJwt(token);
      },
    });
  }
}

function showChatElements() {
  const urlBrand = getParamFromURL('brand');
  const mpxn = getParamFromURL('mpxn');

  $('.mpxnInput').val(mpxn);
  $('.commsCheck401ErrorContainer').hide();
  $('#startChatFirstPage').show();
  if (urlBrand === 'OVO' || urlBrand === 'BOOST') {
    $(`input[name="brand"][value="${urlBrand}"]`).prop('checked', true);
    // eslint-disable-next-line no-undef
    showChatReasons();
  }
  $('.brand-options-container').show();
}

function parseJwt(token) {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(atob(base64).split('').map((c) => `%${(`00${c.charCodeAt(0).toString(16)}`).slice(-2)}`).join(''));
  const baseToken = JSON.parse(jsonPayload);
  const { email } = baseToken;
  userName = getUsername(email);
  emailDomain = getEmailDomain(email);
  localStorage.setItem('userName', userName);
  localStorage.setItem('emailDomain', emailDomain);
  /* check accesstoken start */
  if (localStorage.getItem('accessToken')) {
    showChatElements();
  }
  /* check accesstoken end */
  $('.limiter').show();
  $('#section-chat').hide();
}

function getUsername(email) {
  return email.split('@')[0];
}

function getEmailDomain(email) {
  return email.split('@')[1];
}

const checkValueIsPresent = (argument) => !!argument;

function findChatReasonLevel(list, chatReason) {
  return list.find((option) => option.text === chatReason)?.queue ?? DEFAULT_CHAT_REASON_LEVEL;
}

$(() => {
  // The following de-encodes query string variables that have been passed through cognito and puts
  // them back into the query string for this page
  getStateFromURL();
  const tokenVal = getParamFromURL('code');
  if (tokenVal !== '' && tokenVal !== null && tokenVal !== undefined) {
    getToken(tokenVal);
  }
  /* check accesstoken start */
  if (localStorage.getItem('accessToken')) {
    showChatElements();
  } else {
    $('#startChatFirstPage ').hide();
    $('.commsCheck401ErrorContainer').show();
  }
  /* check accesstoken end */
});

function getChatReasonLevel(chatReason, selectedBrand) {
  if (selectedBrand === 'OVO') {
    // eslint-disable-next-line no-undef
    return findChatReasonLevel(ovoSelectOptions, chatReason);
  } if (selectedBrand === 'BOOST') {
    // eslint-disable-next-line no-undef
    return findChatReasonLevel(boostSelectOptions, chatReason);
  }
  return DEFAULT_CHAT_REASON_LEVEL;
}

export default function startChat() {
  // eslint-disable-next-line no-undef
  if (!validateStartChatInfo()) {
    return;
  }

  sessionuserName = localStorage.getItem('userName');
  sessionEmailDomain = localStorage.getItem('emailDomain');
  sessionaccessToken = localStorage.getItem('accessToken');
  const selectedBrand = $('input[name=brand]:checked').val();
  const chatReason = $('.dd-selected-text').text();
  const chatReasonLevel = getChatReasonLevel(chatReason, selectedBrand);
  if (chatReason === 'Checking comms onsite') {
    goBackLogic();
  }
  if (checkValueIsPresent(sessionuserName)
    && checkValueIsPresent(sessionaccessToken)
    && checkValueIsPresent(sessionEmailDomain)
    && checkValueIsPresent(chatReason)
    && checkValueIsPresent(selectedBrand)) {
    userName = sessionuserName;
    accessToken = sessionaccessToken;
    emailDomain = sessionEmailDomain;

    const customerName = userName;

    const connectParams = getParamsFromURL();
    connectParams.customerName = customerName;
    connectParams.emailDomain = emailDomain;
    connectParams.chatReason = chatReason;
    connectParams.chatReasonLevel = chatReasonLevel;
    connectParams.brand = selectedBrand;
    connectParams.commsCheckResponseData = window.commsCheckResponseData;
    delete connectParams.code;

    $('.divSpinner').delay(10).fadeIn();
    $('#chatWrapper').css('display', 'block');
    $('.limiter').fadeOut();
    const shadesEl = document.querySelector('.connect-customer-interface');
    shadesEl.classList.remove('sc-gipzik');
    shadesEl.classList.remove('eYCHsU');
    const username = 'UserName';
    // eslint-disable-next-line no-undef
    connect.ChatInterface.initiateChat({
      headers: {
        Authorizer: accessToken,
      },
      name: customerName,
      username,
      region: process.env.REACT_APP_AWS_REGION,
      apiGatewayEndpoint: process.env.REACT_APP_AWS_LAMBDA_CHECK_ACTIVE_CHAT_URL,
      contactAttributes: JSON.stringify(connectParams),
    }, successHandler, failureHandler);

    $('#chatWrapper').hide();
    $('#section-chat').show('slide');
  }
}

function successHandler(chatSession) {
  /* Remove Error Span if success case start */
  $('#accessDeniedError').hide();
  $('#chatLoadingContainer').hide();

  /* Remove Error Span if success case end */
  window.chatSession = chatSession;
  localStorage.setItem('Startinitiate', 'Yes');
  // Change the incoming data set
  // eslint-disable-next-line no-undef
  connect.ChatSession.setGlobalConfig({
    loggerConfig: {
      logger: {
        error: (msg) => console.error(`Connect Chat Session message:|| ${msg}`),
      },
      // eslint-disable-next-line no-undef
      level: connect.ChatSession.LogLevel.ERROR,
    },
    region: process.env.REACT_APP_AWS_REGION,
  });

  // eslint-disable-next-line no-undef
  connect.WebSocketManager.setGlobalConfig({
    loggerConfig: {
      logger: {
        error: (msg) => console.error(`Connect Websocket Manager message:|| ${msg}`),
      },
      // eslint-disable-next-line no-undef
      level: connect.WebSocketManager.LogLevel.ERROR,
    },
  });

  // eslint-disable-next-line no-param-reassign
  chatSession.incomingItemDecorator = (item) => {
    if (['SYSTEM_MESSAGE'].indexOf(item.displayName) !== -1) {
      // eslint-disable-next-line no-param-reassign
      item.displayName = 'MILES';
    }

    if (item.displayName === 'BOT') {
      // eslint-disable-next-line no-param-reassign
      item.displayName = 'MILES';
    }
    if (item.displayName === 'SYSTEM_MESSAGE') {
      // eslint-disable-next-line no-param-reassign
      item.displayName = 'MILES';
    }

    if (chatSession.transcript.length > 0) {
      // chat connected
      $('.divSpinner').hide();
      $('#chatWrapper').show();
      const transcriptItem = chatSession.transcript[chatSession.transcript.length - 1];
      if (transcriptItem.transportDetails.direction === 'Incoming') {
        let chatDescription = 'This is a demo of a customer chat experience.';
        const name = transcriptItem.displayName;
        if (['prod', '$LATEST', 'AI Assistant', 'SYSTEM_MESSAGE', 'System Message'].indexOf(name) === -1) {
          chatDescription = `You are now chatting with ${name}`;
        }
        document.getElementById('chatDescription').innerHTML = chatDescription;
      }
    }

    return item;
  };

  chatSession.onIncoming((data) => {
    console.log(`incoming message:|| ${JSON.stringify(data)}`);
    const myAudio = document.getElementById('my-audio');
    myAudio.play();
  });

  chatSession.onOutgoing((data) => {
    console.log(`outgoing message: ${JSON.stringify(data)}`);
  });

  chatSession.onChatDisconnected(() => {
    resetForm();
  });

  function resetForm() {
    $('#section-chat').removeAttr('style');
    $('#nav').css('width', '690');
    $('#section-chat').hide('slide');
    $('.limiter').fadeIn(200);
    window.chatSession = '';
  }

  // eslint-disable-next-line no-undef
  connect.ChatInterface.init({
    containerId: 'root',
    headerConfig: {
      isHTML: true,
      render: () => (`
                <div class="header-wrapper" style="display: none;">
                <div style="display=flex;">
                <div>
                    <h2 class="welcome-text">Metering Installation Support</h2>
                    <p id="chatDescription"></p>
                </div>
                `),
    },
  });
}

function failureHandler(error) {
  console.log(error);
  setTimeout(() => {
    if (error.status === 401) {
      $('.divSpinner').hide();
      $('#section-chat').hide();
      $('.limiter').show();
      if (error.json.message === 'The incoming token has expired') {
        $('#accessDeniedError').hide();
        $('#chatError').hide();
        $('#sessionError').show();
      }
      if (error.json.message === 'Unauthorized') {
        $('#accessDeniedError').hide();
        $('#chatError').show();
        $('#sessionError').hide();
      }
    } else// Access denied error case
    if (error._debug !== undefined) {
      const errorConnectSuccess = error.connectSuccess;
      const errorAccessDenied = error._debug._debug.message;
      const errorReason = error._debug.reason;
      if (!errorConnectSuccess && errorAccessDenied === 'Access denied' && errorReason === 'Failed to fetch connectionDetails with createParticipantConnection') {
        $('.divSpinner').hide();
        $('#section-chat').hide();
        $('.limiter').show();
        $('#sessionError').hide();
        $('#accessDeniedError').show();
      }
    } else {
      $('#accessDeniedError').hide();
      $('.divSpinner').hide();
      $('#section-chat').hide();
      $('.limiter').show();
      $('#sessionError').show();
    }
  }, 500);
}

window.onload = () => {
  // eslint-disable-next-line no-restricted-globals
  if (typeof history.pushState === 'function') {
    // eslint-disable-next-line no-restricted-globals
    history.pushState('jibberish', null, null);
    window.onpopstate = () => {
      // eslint-disable-next-line no-restricted-globals
      history.pushState('newjibberish', null, null);
      // eslint-disable-next-line no-restricted-globals, no-alert
      if (confirm('You are leaving this chat, press OK to continue')) {
        if (this.chatSession !== '') {
          this.chatSession.endChat();
        }
      } else {
        // eslint-disable-next-line no-alert
        alert('Thanks ! You chat is still active');
      }
    };
  }
};
