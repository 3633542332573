import React from 'react';
import { logOut } from '../session';
import sendCommsCheckGenerateXml from './comms-check';

export default function CommsCheck() {
  return (
    <>
      <div className="commsCheckContainer" id='commsCheckContainer' style={{ display: 'none' }}>
        <form name="commsCheckForm" id="commsCheckForm" onSubmit={ (e) => e.preventDefault() }>
          <div className="commsCheckInputContainer">
            <label htmlFor="mpxnInput">What's your MPxN?</label>
            <input type="text" id="mpxnInput" className="commsCheckInputBox mpxnInput" name="commsCheck" placeholder="Enter your MPxN here"/>
            <span className="errorMessage noMpxnEnteredError" style={{ display: 'none' }}>Error: Please enter the MPxN</span>
            <span className="errorMessage incorrectMpxnLengthError" style={{ display: 'none' }}>Error: Please check the MPxN</span>
          </div>
          <div className="commsCheckInputContainer">
            <label htmlFor="msnInput">What's your MSN?</label>
            <input type="text" id="msnInput" className="commsCheckInputBox msnInput" name="commsCheck" placeholder="Enter your MSN here"/>
            <span className="errorMessage noMsnEnteredError" style={{ display: 'none' }}>Error: Please enter the MSN</span>
            <span className="errorMessage incorrectMsnLengthError" style={{ display: 'none' }}>Error: Please check the MSN</span>
          </div>
          <div className="information">
            <p>No MSN? Please Select "Checking an MSN"</p>
          </div>
          <div className="commsCheckInputContainer">
            <label htmlFor="chfInput" style={{ display: 'none' }}>Comms Hub GUID</label>
            <input type="text" id="chfInput" className="commsCheckInputBox chfInput" name="commsCheck" placeholder="CHF GUID will be displayed here" disabled style={{ display: 'none' }}/>
            <span className="errorMessage noChfEnteredError" style={{ display: 'none' }}>Error: Please enter the GUID</span>
            <span className="errorMessage incorrectChfLengthError" style={{ display: 'none' }}>Error: Please check the GUID</span>
          </div>
          <div className="btn-flex-container buttons-fixed">
            <div className="wrap-login100 chatBtns">
              <div className="container-login100-form-btn">
                <button className="primaryBtn primaryButtonEnabled m-b-10" id="startCommsCheck" onClick={ () => sendCommsCheckGenerateXml() }>Start Comms Check</button>
                <button className="secondaryBtn" id="waitingScreenLogout" onClick={ logOut }>Log Out</button>
              </div>
            </div>
          </div>
          <div className="emptySpaceForFixedButtons two-buttons-height"></div>
        </form>
      </div>
      <div className="infoContainer" style={{ display: 'none' }}>
        <div className="error-result-inputs" style={{ display: 'none' }}>
          <div className="commsCheckInputContainer">
            <label htmlFor="mpxnInput">Current MPxN</label>
            <input type="text" className="commsCheckInputBox mpxnInput smallInput" name="commsCheck" placeholder="Enter your MPxN here"/>
            <span className="errorMessage noMpxnEnteredError" style={{ display: 'none' }}>Error: Please enter the MPxN</span>
            <span className="errorMessage incorrectMpxnLengthError" style={{ display: 'none' }}>Error: Please check the MPxN</span>
          </div>
          <div className="commsCheckInputContainer">
            <label htmlFor="msnInput">Current MSN</label>
            <input type="text" className="commsCheckInputBox msnInput smallInput" name="commsCheck" placeholder="Enter your MSN here"/>
            <span className="errorMessage noMsnEnteredError" style={{ display: 'none' }}>Error: Please enter the MSN</span>
            <span className="errorMessage incorrectMsnLengthError" style={{ display: 'none' }}>Error: Please check the MSN</span>
          </div>
          <div className="commsCheckInputContainer">
            <label htmlFor="chfInput" style={{ display: 'none' }}>Current CHF GUID</label>
            <input type="text" className="commsCheckInputBox chfInput smallInput" name="commsCheck" placeholder="CHF GUID will be displayed here" disabled style={{ display: 'none' }}/>
            <span className="errorMessage noChfEnteredError" style={{ display: 'none' }}>Error: Please enter the CHF GUID</span>
            <span className="errorMessage incorrectChfLengthError" style={{ display: 'none' }}>Error: Please check the CHF GUID</span>
          </div>
        </div>
        <div className="informationPanel results">
        </div>
        <div id="result-buttons" className="btn-flex-container buttons-fixed">
        </div>
      </div>
      <div className="commsCheck401ErrorContainer" style={{ display: 'none' }}>
        <div className="commsCheck401ErrorInfoContainer">
          <div className="information">
            <h1 className="errorHeader">Error 401: Unauthorised User</h1>
            <p>This has happened because your session has timed out</p>
            <p>Please log out and back in, then try again</p>
          </div>
          <div className="commsCheck401ErrorBtnContainer buttons-fixed">
            <button className="secondaryBtn" id="waitingLogout" onClick={ logOut }>Log Out</button>
          </div>
        </div>
      </div>
    </>
  );
}
