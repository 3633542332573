import React from 'react';
import { logOut } from '../session';
import startChat from './chat-jq';

export default function Chat() {
  return (
    <form name="contactDetail" id="contactDetails" className="validate-form login100-form" action="/index.html" onSubmit={ (e) => e.preventDefault() }>
      <div className="brand-options-container" style={{ display: 'none' }}>
        <p className="chat-reasons-p">What brand are you contacting about?</p>
        <div className="brand-container">
          <label htmlFor="OVO" className="brand-radio-btn">OVO</label>
          <input type="radio" className="brand-radio-btn" id="OVO" name="brand" value="OVO"/>
        </div>
        <hr className="solid"/>
        <div className="brand-container">
          <label htmlFor="BOOST" className="brand-radio-btn">BOOST</label>
          <input type="radio" className="brand-radio-btn" id="BOOST" name="brand" value="BOOST"/>
        </div>
      </div>
      <div className="chat-reasons-container" style={{ display: 'none' }}>
        <p className="chat-reasons-p">What issue are you having?</p>
        <div id="chat-reasons-dropdown"></div>
      </div>
      <span id="chatBrandError" style={{ display: 'none' }}>No brand was selected. <br/>Please select a brand and try again</span>
      <span id="chatReasonsError" style={{ display: 'none' }}>No Chat Reason was selected. <br/>Please select a Chat Reason and try again</span>
      <div className="btn-flex-container buttons-fixed">
        <div className="wrap-login100 chatBtns">
          <div className="container-login100-form-btn">
            <button className="primaryBtn primaryButtonDisabled m-b-10" style={{ display: 'none' }} id="startChatFirstPage" onClick={ startChat }>Start Chat</button>
            <button className="secondaryBtn" id="logoutFirstPage" onClick={ logOut }>Log Out</button>
            <span id="chatError" style={{ display: 'none' }}>Unauthorized User<br/> Please log out and log in again</span>
            <span id="sessionError" style={{ display: 'none' }}>Sorry, your session has expired<br/> Please log out and log in again</span>
            <span id="accessDeniedError" style={{ display: 'none' }}>Oops, try again in 10 seconds</span>
          </div>
        </div>
      </div>
    </form>
  );
}
