// eslint-disable-next-line import/no-extraneous-dependencies
import $ from 'jquery';
import { logOut } from '../session';
import startChat from '../chat/chat-jq';
import goBackLogic from '../utils';
import createButtonContainer from './buttons';

function goBack() {
  window.abortRequests = true;
  goBackLogic();
}

const loadingButtons = [
  {
    text: 'Go Back', id: 'goBackLoadingPage', classes: 'primaryBtn primaryButtonEnabled', onClick: goBack,
  },
  {
    text: 'Logout', id: 'LogoutLoadingPage', classes: 'secondaryBtn', onClick: logOut,
  },
];
export const successButtons = [
  {
    text: 'Retry Comms Check', id: 'retryCommsCheck', classes: 'primaryBtn primaryButtonEnabled', onClick: sendCommsCheckGenerateXml,
  },
  {
    text: 'Ask About Something Else', id: 'goBackLoadingPage', classes: 'secondaryBtn', onClick: goBack,
  },
];

export const successWithOtherDevicesButtons = [
  {
    text: 'Start Chat', id: 'startChat', classes: 'primaryBtn primaryButtonEnabled', onClick: startChat,
  },
  {
    text: 'Retry Comms Check', id: 'retryCommsCheck', classes: 'secondaryBtn', onClick: sendCommsCheckGenerateXml,
  },
  {
    text: 'Ask About Something Else', id: 'goBackLoadingPage', classes: 'secondaryBtn', onClick: goBack,
  },
];

export const failOrTimeoutButtons = [
  {
    text: 'Retry Comms Check', id: 'retryCommsCheck', classes: 'primaryBtn primaryButtonEnabled', onClick: sendCommsCheckGenerateXml,
  },
  {
    text: 'Start Chat', id: 'startChat', classes: 'secondaryBtn', onClick: startChat,
  },
  {
    text: 'Go Back', id: 'goBackLoadingPage', classes: 'secondaryBtn', onClick: goBack,
  },
];

const serverErrorButtons = [
  {
    text: 'Retry Comms Check',
    id: 'retryCommsCheck',
    classes: 'primaryBtn primaryButtonEnabled',
    onClick: sendCommsCheckGenerateXml,
  },
  {
    text: 'Start Chat', id: 'startChat', classes: 'secondaryBtn', onClick: startChat,
  },
  {
    text: 'Go Back', id: 'goBackLoadingPage', classes: 'secondaryBtn', onClick: goBack,
  },
];

export const wrongGuidErrorButtons = [
  {
    text: 'Start Chat', id: 'startChat', classes: 'primaryBtn primaryButtonEnabled', onClick: startChat,
  },
  {
    text: 'Go Back', id: 'goBackLoadingPage', classes: 'secondaryBtn', onClick: goBack,
  },
];

let generateXmlRequests = [];
let getXmlRequests = [];

function validateMpxn(mpxn) {
  if (/^\d{6,13}$/.test(mpxn)) {
    $('.incorrectMpxnLengthError').hide();
    $('.noMpxnEnteredError').hide();
    return true;
  }
  if (mpxn.length === 0) {
    // show empty input error
    $('.incorrectMpxnLengthError').hide();
    $('.noMpxnEnteredError').show();
    return false;
  }
  // show invalid length error
  $('.noMpxnEnteredError').hide();
  $('.incorrectMpxnLengthError').show();
  return false;
}

function validateMsn(msn) {
  if (/^[a-zA-Z0-9]{8,14}$/.test(msn)) {
    $('.incorrectMsnLengthError').hide();
    $('.noMsnEnteredError').hide();
    return true;
  }
  if (msn.length === 0) {
    // show empty input error
    $('.incorrectMsnLengthError').hide();
    $('.noMsnEnteredError').show();
    return false;
  }
  // show invalid length error
  $('.noMsnEnteredError').hide();
  $('.incorrectMsnLengthError').show();
  return false;
}

// function validateChf(chf) {
//   if (/^([0-9A-Fa-f]{2}[-]){7}([0-9A-Fa-f]{2})$/.test(chf)) {
//     $('.incorrectChfLengthError').hide();
//     $('.noChfEnteredError').hide();
//     return true;
//   }
//   if (chf.length === 0) {
//     // show empty input error
//     $('.incorrectChfLengthError').hide();
//     $('.noChfEnteredError').show();
//     return false;
//   }
//   // show invalid length error
//   $('.noChfEnteredError').hide();
//   $('.incorrectChfLengthError').show();
//   return false;
// }

// this will add hyphens to the chf input box as the engineer types in the guid
$('.chfInput').keyup((e) => {
  const caretPosition = e.target.selectionStart;
  let chf = $(this).val().split('-').join(''); // remove hyphens
  if (chf.length > 0) {
    chf = chf.match(new RegExp('.{1,2}', 'g')).join('-');
  }
  $(this).val(chf);
  e.target.selectionEnd = caretPosition % 3 === 0 ? caretPosition + 1 : caretPosition; // preserve caret after formatting
});

function showServerError() {
  buildServerErrorScreen();
  createButtonContainer(serverErrorButtons);
}

function timeoutScreen(responseData) {
  $('.error-result-inputs').show();
  window.commsCheckResponseData = buildCommsCheckStartChatInformation(responseData);
  buildTimeoutScreen(responseData);
  createButtonContainer(failOrTimeoutButtons);
}

function validateCommsCheckData(mpxn, msn) {
  const isMpxnValid = validateMpxn(mpxn);
  const isMsnValid = validateMsn(msn);

  return isMpxnValid && isMsnValid;
}

function onSuccess(dataL1) {
  let retryCount = 1;
  function l2Retry(responseData) {
    if (retryCount < process.env.REACT_APP_COMMS_CHECK_GET_XML_MAX_RETRIES) {
      retryCount += 1;
      setTimeout(sendCommsCheckGetXml, process.env.REACT_APP_COMMS_CHECK_GET_XML_TIMEOUT);
    } else {
      timeoutScreen(responseData);
      console.error('L2 Retries exhausted');
    }
  }

  function checkResponseState(responseData) {
    const readInventorySucceeded = responseData.bolQueriesState[0] === 'SUCCESS';
    const readInstantaneousImportSucceeded = responseData.bolQueriesState[1] === 'SUCCESS';
    const readDeviceLogSucceeded = responseData.bolQueriesState[2] === 'SUCCESS';
    if (readInventorySucceeded && readInstantaneousImportSucceeded && readDeviceLogSucceeded) {
      buildCommsCheckSuccessScreen(responseData);
    } else {
      buildCommsCheckFailScreen(responseData);
    }
  }

  function triggerNewGenerateXml(data) {
    if (data.bolQueriesState[0] === 'SUCCESS') {
      const chfDevice = data.atomL2response[0].xml_string.Body.XRINV_OUT_02.InventoryDevice.find((device) => device.DeviceType === 'CHF');

      if (chfDevice.DeviceID) {
        $('.chfInput').val(chfDevice.DeviceID);
        return true;
      }
    }

    return false;
  }

  function sendCommsCheckGetXml() {
    window.abortRequests = false;
    const sessionAccessToken = localStorage.getItem('accessToken');

    getXmlRequests[getXmlRequests.length] = $.ajax({
      type: 'POST',
      headers: {
        Authorization: `Bearer ${sessionAccessToken}`,
      },
      url: process.env.REACT_APP_GET_XML_PROXY_LAMBDA_URL,
      dataType: 'json',
      data: JSON.stringify(dataL1.atomL1response),
      contentType: 'application/json',
      success(data) {
        if (window.abortRequests) {
          getXmlRequests = [];
        } else if (getXmlRequests.length > 1) {
          getXmlRequests.splice(0, 1);
        } else {
          getXmlRequests = [];

          if (triggerNewGenerateXml(data) && window.submittedCHF === '') {
            sendCommsCheckGenerateXml(false);
          } else {
            switch (data.commsCheckState) {
              case 'PROCESSING':
                l2Retry(data);
                break;
              case 'COMPLETED':
                checkResponseState(data);
                break;
              default: {
                console.error('Atom L2 unknown state. Response data: ', data);
                showServerError();
              }
            }
          }
        }
      },
      error(xhr, status, error) {
        showServerError();
        console.error('L2 error: ', status, error);
      },
    });
  }
  setTimeout(sendCommsCheckGetXml, process.env.REACT_APP_COMMS_CHECK_GET_XML_INITIAL_TIMEOUT);
}

function prepareInventoryPayload(mpxn, msn) {
  const commsCheckPayload = {
    data: [
      {
        xrinv_in_01: {
          Identifier: mpxn,
        },
      },
      {
        xritm_in_01: {
          ImportMPxN: mpxn,
          MSN: msn,
        },
      },
      {
        xrdlg_in_01: {
          ImportMPxN: mpxn,
          Identifier: msn,
        },
      },
    ],
  };

  return JSON.stringify(commsCheckPayload);
}

function preparePayload(mpxn, msn, chf) {
  const commsCheckPayload = {
    data: [
      {
        xrinv_in_01: {
          Identifier: mpxn,
        },
      },
      {
        xritm_in_01: {
          ImportMPxN: mpxn,
          MSN: msn,
        },
      },
      {
        xrdlg_in_01: {
          ImportMPxN: mpxn,
          Identifier: chf,
        },
      },
    ],
  };
  return JSON.stringify(commsCheckPayload);
}

export default function sendCommsCheckGenerateXml(isInventoryRequest = true) {
  window.abortRequests = false;
  const mpxn = $('.mpxnInput').val();
  const msn = $('.msnInput').val();
  const chf = $('.chfInput').val();

  const sessionAccessToken = localStorage.getItem('accessToken');
  if (!sessionAccessToken) {
    $('#contactDetails').hide();
    $('.brand-options-container').hide();
    $('.chat-reasons-container').hide();
    $('.commsCheckContainer').hide();
    $('.commsCheck401ErrorContainer').show();
    return;
  }

  if (validateCommsCheckData(mpxn, msn, chf)) {
    let commsCheckPayloadStringified;
    if (isInventoryRequest) {
      commsCheckPayloadStringified = prepareInventoryPayload(mpxn, msn);
    } else {
      commsCheckPayloadStringified = preparePayload(mpxn, msn, chf);
    }

    // Show waiting for response screen
    $('.commsCheckContainer').hide();
    $('#contactDetails').hide();
    buildLoadingPanel();
    $('.error-result-inputs').hide();
    createButtonContainer(loadingButtons);
    $('.infoContainer').show();

    // send payload to Atom Proxy Lambda
    generateXmlRequests[generateXmlRequests.length] = $.ajax({
      type: 'POST',
      headers: {
        Authorization: `Bearer ${sessionAccessToken}`,
      },
      url: process.env.REACT_APP_GENERATE_XML_PROXY_LAMBDA_URL,
      dataType: 'json',
      data: commsCheckPayloadStringified,
      contentType: 'application/json',
      success(data) {
        if (window.abortRequests) {
          generateXmlRequests = [];
        } else if (generateXmlRequests.length > 1) {
          generateXmlRequests.splice(0, 1);
        } else {
          generateXmlRequests = [];

          window.submittedMPxN = mpxn;
          window.submittedMSN = msn;
          window.submittedCHF = isInventoryRequest ? '' : chf;
          onSuccess(data);
        }
      },
      error(xhr, status, error) {
        console.error('AJAX error:', status, error);
        if (xhr.status === 401) {
          $('.infoContainer').hide();
          $('#waitingLogout').show();
          $('.commsCheck401ErrorContainer').show();
        } else {
          showServerError();
        }
      },
    });
  }
}

const getFailTimeoutInformationMessage = ({ readInventoryStatus = false, readTouMatriceStatus = false, readDeviceLogStatus = false }) => {
  const readInventoryFailTimeoutMessage = 'Unable to retrieve devices from the DCC.\n';
  const readTouMatriceFailTimeoutMessage = `Unable to retrieve readings for MSN: ${window.submittedMSN}.\n`;
  const readDeviceLogFailTimeoutMessage = 'Unable to retrieve last communicated time for the devices.\n';
  if (isBolQueryStateProcessing(readInventoryStatus)) {
    return readInventoryFailTimeoutMessage;
  }
  if (isBolQueryStateFail(readInventoryStatus)) {
    return readInventoryFailTimeoutMessage;
  }
  if (isBolQueryStateProcessing(readTouMatriceStatus)) {
    return readTouMatriceFailTimeoutMessage;
  }
  if (isBolQueryStateFail(readTouMatriceStatus)) {
    return readTouMatriceFailTimeoutMessage;
  }
  if (isBolQueryStateProcessing(readDeviceLogStatus)) {
    return readDeviceLogFailTimeoutMessage;
  }
  if (isBolQueryStateFail(readDeviceLogStatus)) {
    return readDeviceLogFailTimeoutMessage;
  }

  return '';
};

function emptyInformationPanel() {
  $('.informationPanel').empty();
}

function buildLoadingPanel() {
  emptyInformationPanel();
  const $infoPanel = $('<div></div>').addClass('informationPanelLoading');

  $('<h1>Information:</h1>').addClass('informationBoldedLoading').appendTo($infoPanel);

  $('<p>Collecting information...</p>').addClass('informationNormalLoading').appendTo($infoPanel);

  $('<p>The average wait time is around 30 seconds.</p>').addClass('informationNormalLoading').appendTo($infoPanel);

  $('<p>This can take up to 3 minutes.</p>').addClass('informationNormalLoading').appendTo($infoPanel);

  $infoPanel.appendTo('.informationPanel');
}

function buildResult(result) {
  const $resultDiv = $('<div></div>').addClass('m-b-10');

  $('<span>Result:  </span>').addClass('resultKey').appendTo($resultDiv);

  $(`<span>${result}</span>`).addClass('resultValue').appendTo($resultDiv);
  $resultDiv.appendTo('.informationPanel');
}

function buildInformationHeading(readInventorySucceeded, readInstantaneousImportSucceeded, readDeviceLogSucceeded) {
  if (readInventorySucceeded || readInstantaneousImportSucceeded || readDeviceLogSucceeded) {
    const infoHeading = $('<div>Information:</div>').addClass('informationHeading');

    infoHeading.appendTo('.informationPanel');
  }
}

function buildInformationSection(type, status, GUID) {
  const $infoSection = $('<div></div>').addClass('informationSection');

  $(`<div>${type}:</div>`).addClass('informationSectionText').appendTo($infoSection);

  const $infoRow = $('<div></div>').addClass('informationRow');

  $(`<div><span>Status: </span><span>${status}</span></div>`)
    .find('span').addClass('informationSectionText').end()
    .appendTo($infoRow);

  $(`<div><span>GUID: </span><span>${GUID}</span></div>`)
    .find('span').addClass('informationSectionText').end()
    .appendTo($infoRow);

  $infoRow.appendTo($infoSection);

  $infoSection.appendTo('.informationPanel');
}

function buildActionToTake(action) {
  const actionToTake = $('<div></div>').addClass('informationRow');
  $('<div>Actions To Take:</div>').addClass('informationHeading').appendTo(actionToTake);
  $(`<div>${action}</div>`).addClass('informationSectionText').appendTo(actionToTake);

  actionToTake.appendTo('.informationPanel');
}

function isBolQueryStateSuccess(state) {
  return state === 'SUCCESS';
}

function isBolQueryStateFail(state) {
  return state === 'FAIL';
}

function isBolQueryStateProcessing(state) {
  return state === 'PROCESSING';
}

function buildTimeoutPanel() {
  let timeoutInformation = $('.timeoutInformationSection');
  if (!timeoutInformation.length) {
    timeoutInformation = $('<div></div>').addClass('informationRow timeoutInformationSection');
    timeoutInformation.appendTo('.informationPanel');
  }

  if (!$('.timeoutInformationHeading').length) {
    $('<div>Timeout Information:</div>').addClass('informationHeading timeoutInformationHeading').appendTo(timeoutInformation);
  }
}

function buildFailPanel() {
  let failInformation = $('.failInformationSection');
  if (!failInformation.length) {
    failInformation = $('<div></div>').addClass('informationRow fail-information-panel');
    failInformation.appendTo('.informationPanel');
  }

  if (!$('.failInformationHeading').length) {
    $('<div>Fail Information:</div>').addClass('informationHeading failInformationHeading').appendTo('.fail-information-panel');
  }
}

function appendToFailInformationPanel(message) {
  return $(`<div>${message}</div>`).addClass('informationSectionText').appendTo('.fail-information-panel');
}

function appendToTimeoutInformationPanel(message) {
  return $(`<div>${message}</div>`).addClass('informationSectionText').appendTo('.timeoutInformationSection');
}

function appendInformationToPanel(responseData) {
  const readInventoryStatus = responseData.bolQueriesState[0];
  const readTOUMatriceStatus = responseData.bolQueriesState[1];
  const readDeviceLogStatus = responseData.bolQueriesState[2];

  if (isBolQueryStateProcessing(readInventoryStatus)) {
    appendToTimeoutInformationPanel('Unable to retrieve devices from the DCC.');
  }
  if (isBolQueryStateFail(readInventoryStatus)) {
    appendToFailInformationPanel('Unable to retrieve devices from the DCC.');
  }
  if (isBolQueryStateProcessing(readTOUMatriceStatus)) {
    appendToTimeoutInformationPanel(`Unable to retrieve readings for MSN: ${window.submittedMSN}.`);
  }
  if (isBolQueryStateFail(readTOUMatriceStatus)) {
    appendToFailInformationPanel(`Unable to retrieve readings for MSN: ${window.submittedMSN}.`);
  }
  if (isBolQueryStateProcessing(readDeviceLogStatus)) {
    appendToTimeoutInformationPanel('Unable to retrieve last communicated time for the devices.');
  }
  if (isBolQueryStateFail(readDeviceLogStatus)) {
    appendToFailInformationPanel('Unable to retrieve last communicated time for the devices.');
  }
}

export function buildTimeoutScreen(responseData) {
  const readInventoryStatus = responseData.bolQueriesState[0];
  const readInventorySucceeded = isBolQueryStateSuccess(readInventoryStatus);
  const readTOUMatriceStatus = responseData.bolQueriesState[1];
  const readInstantaneousImportSucceeded = isBolQueryStateSuccess(readTOUMatriceStatus);
  const readDeviceLogStatus = responseData.bolQueriesState[2];
  const readDeviceLogSucceeded = isBolQueryStateSuccess(readDeviceLogStatus);

  let otherDevicesArray;
  if (readInventorySucceeded && readDeviceLogSucceeded) {
    const readInventoryData = responseData.atomL2response[0].xml_string.Body.XRINV_OUT_02.InventoryDevice;
    const readDeviceLogData = responseData.atomL2response[2].xml_string.Body.XRDLG_OUT_02.DeviceLogEntry;
    otherDevicesArray = getOtherDevices(readDeviceLogData, readInventoryData);
  }

  emptyInformationPanel();
  buildResult(determineResult(responseData));
  if (isBolQueryStateProcessing(readInventoryStatus) || isBolQueryStateProcessing(readTOUMatriceStatus) || isBolQueryStateProcessing(readDeviceLogStatus)) {
    buildTimeoutPanel();
  }
  if (isBolQueryStateFail(readInventoryStatus) || isBolQueryStateFail(readTOUMatriceStatus) || isBolQueryStateFail(readDeviceLogStatus)) {
    buildFailPanel();
  }
  appendInformationToPanel(responseData);
  buildActionToTake(determineAction(responseData));
  buildInformationHeading(readInventorySucceeded, readInstantaneousImportSucceeded, readDeviceLogSucceeded);
  buildInventoryInformation(readInventorySucceeded, readDeviceLogSucceeded, responseData);
  buildInstantaneousImportInformation(readInstantaneousImportSucceeded, responseData);
  buildOtherDevicesInformation(readInventorySucceeded, readDeviceLogSucceeded, otherDevicesArray, responseData);
  createButtonContainer(failOrTimeoutButtons);
}

function commsCheckStartChatPrintFailInformation(readInventoryStatus, readTouMatriceStatus, readDeviceLogStatus) {
  let text = '';
  if (isBolQueryStateFail(readInventoryStatus)) {
    text += getFailTimeoutInformationMessage({ readInventoryStatus });
  }
  if (isBolQueryStateFail(readTouMatriceStatus)) {
    text += getFailTimeoutInformationMessage({ readTouMatriceStatus });
  }
  if (isBolQueryStateFail(readDeviceLogStatus)) {
    text += getFailTimeoutInformationMessage({ readDeviceLogStatus });
  }
  return text;
}

function commsCheckStartChatPrintTimeoutInformation(readInventoryStatus, readTouMatriceStatus, readDeviceLogStatus) {
  let text = '';
  if (isBolQueryStateProcessing(readInventoryStatus)) {
    text += getFailTimeoutInformationMessage({ readInventoryStatus });
  }
  if (isBolQueryStateProcessing(readTouMatriceStatus)) {
    text += getFailTimeoutInformationMessage({ readTouMatriceStatus });
  }
  if (isBolQueryStateProcessing(readDeviceLogStatus)) {
    text += getFailTimeoutInformationMessage({ readDeviceLogStatus });
  }
  return text;
}

function buildReadInventoryDevicesConnectMessage(type, status, GUID, readDeviceLogData = false) {
  let text = '';
  text += `${type}:\n`;
  text += `Status: ${status}\n`;
  text += `GUID: ${GUID}\n`;

  if (readDeviceLogData) {
    if (Array.isArray(readDeviceLogData)) {
      readDeviceLogData.forEach((info) => {
        if (GUID === info.DeviceID) {
          const readableTimeStamp = makeReadableTimeStamp(info.LastCommunicationTime);
          text += `LCT: ${readableTimeStamp}\n`;
        }
      });
    } else if (GUID === readDeviceLogData.DeviceID) {
      const readableTimeStamp = makeReadableTimeStamp(readDeviceLogData.LastCommunicationTime);
      text += `LCT: ${readableTimeStamp}\n`;
    }
  }
  return text;
}

function buildTouRegisterConnectMessage(touRegister) {
  let text = '';
  text += `Readings for MSN: ${window.submittedMSN}\n`;

  if (Array.isArray(touRegister)) {
    touRegister.forEach((i) => {
      text += `R${i.RegisterID}: ${i.Reading}\n`;
    });
  } else {
    text += `R${touRegister.registerID}: ${touRegister.Reading}\n`;
  }
  text += '\n';
  return text;
}

function buildOtherDevicesConnectMessage(otherDevicesArray) {
  let text = '';
  text += 'Other Devices Found:\n';
  otherDevicesArray.forEach((info) => {
    const readableTimeStamp = makeReadableTimeStamp(info.LastCommunicationTime);
    text += `DeviceID: ${info.DeviceID}\n LCT: ${readableTimeStamp}\n`;
    text += '\n';
  });
  return text;
}

export function buildCommsCheckStartChatInformation(responseData) {
  const readInventoryStatus = responseData.bolQueriesState[0];
  const readInstantaneousImportStatus = responseData.bolQueriesState[1];
  const readDeviceLogStatus = responseData.bolQueriesState[2];

  let text = '';
  text += `MPxN: ${window.submittedMPxN}\n`;
  text += `MSN: ${window.submittedMSN}\n`;
  text += `CHF: ${window.submittedCHF}\n`;
  text += '\n';
  if ((isBolQueryStateFail(readInventoryStatus) || isBolQueryStateFail(readInstantaneousImportStatus) || isBolQueryStateFail(readDeviceLogStatus))) {
    text += 'Fail Information: \n';
    text += commsCheckStartChatPrintFailInformation(readInventoryStatus, readInstantaneousImportStatus, readDeviceLogStatus);
    text += '\n';
  }
  if ((isBolQueryStateProcessing(readInventoryStatus) || isBolQueryStateProcessing(readInstantaneousImportStatus) || isBolQueryStateProcessing(readDeviceLogStatus))) {
    text += 'Timeout Information: \n';
    text += commsCheckStartChatPrintTimeoutInformation(readInventoryStatus, readInstantaneousImportStatus, readDeviceLogStatus);
    text += '\n';
  }
  if ((isBolQueryStateSuccess(readInventoryStatus) || isBolQueryStateSuccess(readInstantaneousImportStatus) || isBolQueryStateSuccess(readDeviceLogStatus))) {
    text += 'Successful Information: \n';
    if ((isBolQueryStateSuccess(readInventoryStatus) && isBolQueryStateSuccess(readDeviceLogStatus))) {
      const readInventoryData = responseData.atomL2response[0].xml_string.Body.XRINV_OUT_02.InventoryDevice;
      const readDeviceLogData = responseData.atomL2response[2].xml_string.Body.XRDLG_OUT_02.DeviceLogEntry;
      readInventoryData.forEach((item) => {
        text += buildReadInventoryDevicesConnectMessage(item.DeviceType, item.DeviceStatus, item.DeviceID, readDeviceLogData);
        text += '\n';
      });
    } else if (isBolQueryStateSuccess(readInventoryStatus)) {
      const readInventoryData = responseData.atomL2response[0].xml_string.Body.XRINV_OUT_02.InventoryDevice;
      readInventoryData.forEach((item) => {
        text += buildReadInventoryDevicesConnectMessage(item.DeviceType, item.DeviceStatus, item.DeviceID);
        text += '\n';
      });
    }
  }
  if (isBolQueryStateSuccess(readInstantaneousImportStatus)) {
    const readInstantaneousImportReadings = responseData.atomL2response[1].xml_string.Body.XRITM_OUT_02.Readings.TOURegister;
    text += buildTouRegisterConnectMessage(readInstantaneousImportReadings);
  }
  if (isBolQueryStateSuccess(readDeviceLogStatus)) {
    const readDeviceLogData = responseData.atomL2response['2'].xml_string.Body.XRDLG_OUT_02.DeviceLogEntry;
    if (isBolQueryStateSuccess(readInventoryStatus)) {
      const readInventoryData = responseData.atomL2response[0].xml_string.Body.XRINV_OUT_02.InventoryDevice;
      const otherDevicesArray = getOtherDevices(readDeviceLogData, readInventoryData);
      if (otherDevicesArray.length >= 1) {
        text += buildOtherDevicesConnectMessage(otherDevicesArray);
      }
    } else if (Array.isArray(readDeviceLogData)) {
      text += buildOtherDevicesConnectMessage(readDeviceLogData);
    } else {
      text += buildOtherDevicesConnectMessage([readDeviceLogData]);
    }
  }
  return text;
}

export function buildServerErrorScreen() {
  emptyInformationPanel();
  buildActionToTake('An error occurred and we were unable to process your comms check request. Please retry the comms check. If this error persists, contact SST.');
}

function buildWrongGuidErrorScreen(mpxn, msn, guid) {
  emptyInformationPanel();
  buildActionToTake(`Incorrect GUID used.<br>Please check the GUID.<br>If this error persists, please contact SST.<br><br>MPxN: ${mpxn}<br>MSN: ${msn}<br>GUID: ${guid}`);
}

function buildTOUReadingSection(TOURegister) {
  const msn = window.submittedMSN;
  const $infoRow = $('<div></div>').addClass('informationRow');

  $(`<div>Readings for MSN: ${msn}</div>`).addClass('informationSectionText').appendTo($infoRow);

  if (Array.isArray(TOURegister)) {
    TOURegister.forEach((i) => {
      $(`<div>R${i.RegisterID}: ${i.Reading}</div>`).addClass('informationSectionText').appendTo($infoRow);
    });
    $infoRow.appendTo('.informationPanel');
    return $infoRow;
  }

  $(`<div>R${TOURegister.RegisterID}: ${TOURegister.Reading}</div>`).addClass('informationSectionText').appendTo($infoRow);
  $infoRow.appendTo('.informationPanel');
  return $infoRow;
}

function makeReadableTimeStamp(readingTimeStamp) {
  const date = new Date(readingTimeStamp);
  const options = {
    year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit',
  };
  return date.toLocaleDateString('en-GB', options).replace(/,/, ' ');
}

function buildTimeStampText(readableTimeStamp) {
  return $(`<div><span>LCT: </span><span>${readableTimeStamp}</span></div>`)
    .find('span').addClass('informationSectionText').end();
}

function buildOtherDevicesSection(devicesArray) {
  if (devicesArray.length >= 1) {
    const $infoRow = $('<div></div>').addClass('informationRow');

    $('<div>Other Devices Found:</div>').addClass('informationHeading').appendTo($infoRow);

    devicesArray.forEach((i) => {
      $(`<div>GUID: ${i.DeviceID}</div>`).addClass('informationSectionText').appendTo($infoRow);
      const readableTimeStamp = makeReadableTimeStamp(i.LastCommunicationTime);
      buildTimeStampText(readableTimeStamp).appendTo($infoRow);
      $('<br/>').appendTo($infoRow);
    });

    $infoRow.appendTo('.informationPanel');
  }
}

function getOtherDevices(readDeviceLogData, readInventoryData) {
  let otherDevicesArray = [];
  if (Array.isArray(readDeviceLogData)) {
    otherDevicesArray = readDeviceLogData.filter((readDeviceLogElement) => readInventoryData
      .filter((readInventoryDataElement) => readInventoryDataElement.DeviceID === readDeviceLogElement.DeviceID).length === 0);
  } else if (!readInventoryData.some((value) => value.DeviceID === readDeviceLogData.DeviceID)) {
    otherDevicesArray.push(readDeviceLogData);
  }
  return otherDevicesArray;
}

function buildInventoryInformation(readInventorySucceeded, readDeviceLogSucceeded, responseData) {
  if (readInventorySucceeded && readDeviceLogSucceeded) {
    responseData.atomL2response['0'].xml_string.Body.XRINV_OUT_02.InventoryDevice.forEach((info) => {
      appendLctToInventoryDevices(info.DeviceType, info.DeviceStatus, info.DeviceID, responseData.atomL2response[2].xml_string.Body.XRDLG_OUT_02.DeviceLogEntry);
    });
  } else if (readInventorySucceeded) {
    responseData.atomL2response['0'].xml_string.Body.XRINV_OUT_02.InventoryDevice.forEach((info) => {
      buildInformationSection(info.DeviceType, info.DeviceStatus, info.DeviceID);
    });
  }
}

function buildInstantaneousImportInformation(readInstantaneousImportSucceeded, responseData) {
  if (readInstantaneousImportSucceeded) {
    const readInstantaneousImportReadings = responseData.atomL2response[1].xml_string.Body.XRITM_OUT_02.Readings.TOURegister;
    buildTOUReadingSection(readInstantaneousImportReadings);
  }
}

function buildOtherDevicesInformation(readInventorySucceeded, readDeviceLogSucceeded, otherDevicesArray, responseData) {
  if (readDeviceLogSucceeded) {
    const deviceLogEntry = responseData.atomL2response['2'].xml_string.Body.XRDLG_OUT_02.DeviceLogEntry;
    if (readInventorySucceeded) {
      if (Array.isArray(otherDevicesArray) && otherDevicesArray.length >= 1) {
        buildOtherDevicesSection(otherDevicesArray);
      }
    } else if (Array.isArray(deviceLogEntry)) {
      buildOtherDevicesSection(deviceLogEntry);
    } else {
      buildOtherDevicesSection([deviceLogEntry]);
    }
  }
}

function determineAction(responseData) {
  const readInventory = responseData.bolQueriesState[0];
  const readInstantaneousImport = responseData.bolQueriesState[1];
  const readDeviceLog = responseData.bolQueriesState[2];

  if ((isBolQueryStateFail(readInventory) || isBolQueryStateProcessing(readInventory))
    && isBolQueryStateSuccess(readInstantaneousImport) && isBolQueryStateSuccess(readDeviceLog)) {
    return 'Assets are in comms, however there is an issue with retrieving the device log from the DCC. Please contact SST.';
  }
  if ((isBolQueryStateFail(readInstantaneousImport) && isBolQueryStateFail(readDeviceLog))
    || (isBolQueryStateFail(readInstantaneousImport) && isBolQueryStateProcessing(readDeviceLog))) {
    return 'If this is the first unsuccessful response, please check the MPxN, MSN, and CHF. If correct, perform a comms hub reboot and try again. If the issue persists, please contact SST.';
  }
  if (isBolQueryStateFail(readInstantaneousImport) && isBolQueryStateSuccess(readDeviceLog)) {
    return 'If this is the first unsuccessful response, please check the MPxN, MSN and CHF. If correct, perform a power cycle and try again. If the issue persists, please contact SST.';
  }
  return 'If this is the first unsuccessful response, please check the MPxN, MSN, and CHF, and then try again. If the issue persists, contact SST.';
}

function determineResult(responseData) {
  const readInventory = responseData.bolQueriesState[0]; // checking if extra cases
  const readInstantaneousImport = responseData.bolQueriesState[1];
  const readDeviceLog = responseData.bolQueriesState[2];

  if (isBolQueryStateSuccess(readInstantaneousImport) && isBolQueryStateSuccess(readDeviceLog)) {
    return 'In comms';
  }
  if ((isBolQueryStateProcessing(readInventory) && isBolQueryStateFail(readInstantaneousImport))
    || (isBolQueryStateFail(readInstantaneousImport) && isBolQueryStateProcessing(readDeviceLog))
    || (!isBolQueryStateProcessing(readInventory) && !isBolQueryStateProcessing(readInstantaneousImport) && !isBolQueryStateProcessing(readDeviceLog))) {
    return 'Not in comms';
  }
  return 'Comms status unknown due to a Timeout';
}

export function buildCommsCheckFailScreen(responseData) {
  const readInventoryStatus = responseData.bolQueriesState[0];
  const readInventorySucceeded = isBolQueryStateSuccess(readInventoryStatus);
  const readTOUMatriceStatus = responseData.bolQueriesState[1];
  const readInstantaneousImportSucceeded = isBolQueryStateSuccess(readTOUMatriceStatus);
  const readDeviceLogStatus = responseData.bolQueriesState[2];
  const readDeviceLogSucceeded = isBolQueryStateSuccess(readDeviceLogStatus);
  let readInventoryData;
  let readDeviceLogData;
  let otherDevicesArray;

  if (readInventorySucceeded) {
    readInventoryData = responseData.atomL2response[0].xml_string.Body.XRINV_OUT_02.InventoryDevice;
  }
  if (readDeviceLogSucceeded) {
    readDeviceLogData = responseData.atomL2response[2].xml_string.Body.XRDLG_OUT_02.DeviceLogEntry;
  }
  if (readInventorySucceeded && readDeviceLogSucceeded) {
    otherDevicesArray = getOtherDevices(readDeviceLogData, readInventoryData);
  }

  $('.error-result-inputs').show();
  emptyInformationPanel();
  buildResult(determineResult(responseData));
  if (isBolQueryStateFail(readInventoryStatus) || isBolQueryStateFail(readTOUMatriceStatus) || isBolQueryStateFail(readDeviceLogStatus)) {
    buildFailPanel();
  }
  appendInformationToPanel(responseData);
  buildActionToTake(determineAction(responseData));
  buildInformationHeading(readInventorySucceeded, readInstantaneousImportSucceeded, readDeviceLogSucceeded);
  buildInventoryInformation(readInventorySucceeded, readDeviceLogSucceeded, responseData);
  buildInstantaneousImportInformation(readInstantaneousImportSucceeded, responseData);
  buildOtherDevicesInformation(readInventorySucceeded, readDeviceLogSucceeded, otherDevicesArray, responseData);
  createButtonContainer(failOrTimeoutButtons);
  window.commsCheckResponseData = buildCommsCheckStartChatInformation(responseData);
}

function appendLctToInventoryDevices(type, status, GUID, readDeviceLogData = false) {
  const $infoSection = $('<div></div>').addClass('informationSection');

  $(`<div>${type}:</div>`).addClass('informationSectionText').appendTo($infoSection);

  const $infoRow = $('<div></div>').addClass('informationRow');

  $(`<div><span>Status: </span><span>${status}</span></div>`)
    .find('span').addClass('informationSectionText').end()
    .appendTo($infoRow);

  $(`<div><span>GUID: </span><span>${GUID}</span></div>`)
    .find('span').addClass('informationSectionText').end()
    .appendTo($infoRow);

  if (readDeviceLogData) {
    if (Array.isArray(readDeviceLogData)) {
      readDeviceLogData.forEach((info) => {
        if (GUID === info.DeviceID) {
          const readableTimeStamp = makeReadableTimeStamp(info.LastCommunicationTime);
          buildTimeStampText(readableTimeStamp).appendTo($infoRow);
        }
      });
    } else if (GUID === readDeviceLogData.DeviceID) {
      const readableTimeStamp = makeReadableTimeStamp(readDeviceLogData.LastCommunicationTime);
      buildTimeStampText(readableTimeStamp).appendTo($infoRow);
    }
  }

  $infoRow.appendTo($infoSection);
  $infoSection.appendTo('.informationPanel');
}

export function buildCommsCheckSuccessScreen(responseData) {
  const readInventoryData = responseData.atomL2response[0].xml_string.Body.XRINV_OUT_02.InventoryDevice;
  const readInstantaneousImportReadings = responseData.atomL2response[1].xml_string.Body.XRITM_OUT_02.Readings.TOURegister;
  const readDeviceLogData = responseData.atomL2response[2].xml_string.Body.XRDLG_OUT_02.DeviceLogEntry;
  const readInventorySucceeded = isBolQueryStateSuccess(responseData.bolQueriesState[0]);
  const readInstantaneousImportSucceeded = isBolQueryStateSuccess(responseData.bolQueriesState[1]);
  const readDeviceLogSucceeded = isBolQueryStateSuccess(responseData.bolQueriesState[2]);

  try {
    const otherDevicesArray = getOtherDevices(readDeviceLogData, readInventoryData);

    emptyInformationPanel();
    buildResult(determineResult(responseData));

    if (otherDevicesArray.length >= 1) {
      buildActionToTake('Other Devices Found. Please contact SST');
      window.commsCheckResponseData = buildCommsCheckStartChatInformation(responseData);
    }

    buildInformationHeading(readInventorySucceeded, readInstantaneousImportSucceeded, readDeviceLogSucceeded);
    readInventoryData.forEach((info) => {
      appendLctToInventoryDevices(info.DeviceType, info.DeviceStatus, info.DeviceID, readDeviceLogData);
    });
    buildTOUReadingSection(readInstantaneousImportReadings);
    buildOtherDevicesSection(otherDevicesArray);

    if (otherDevicesArray.length >= 1) {
      createButtonContainer(successWithOtherDevicesButtons);
    } else {
      createButtonContainer(successButtons);
    }
  } catch (error) {
    buildWrongGuidErrorScreen(window.submittedMPxN, window.submittedMSN, window.submittedCHF);
    createButtonContainer(wrongGuidErrorButtons);
  }
}
