// eslint-disable-next-line import/no-extraneous-dependencies
import $ from 'jquery';

function createButton(buttonText, buttonId, buttonClasses, onClickFunction) {
  return $(`<button id="${buttonId}">${buttonText}</button>`)
    .addClass(buttonClasses)
    .click(onClickFunction);
}

export default function createButtonContainer(buttons) {
  $('#result-buttons').empty();
  $('.infoContainer').find('.emptySpaceForFixedButtons').remove();
  const container = $('<div class="wrap-login100 chatBtns"><div class="container-login100-form-btn"></div></div>');
  buttons.forEach((button, i) => {
    const { classes } = button;
    if (i !== buttons.length - 1) {
      const $button = createButton(button.text, button.id, `${classes} + m-b-10`, button.onClick);
      container.find('.container-login100-form-btn').append($button);
      return;
    }
    const $button = createButton(button.text, button.id, button.classes, button.onClick);
    container.find('.container-login100-form-btn').append($button);
  });
  if (buttons.length === 2) {
    $('.infoContainer').append('<div class="emptySpaceForFixedButtons two-buttons-height"></div>');
  }
  if (buttons.length === 3) {
    $('.infoContainer').append('<div class="emptySpaceForFixedButtons three-buttons-height"></div>');
  }

  container.appendTo('#result-buttons');
}
