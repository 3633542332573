import React from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import HomePage from './components/homepage/HomePage';
import ChatPage from './components/chatpage/ChatPage';

const router = createBrowserRouter([
  { path: '/', element: <HomePage /> },
  { path: '/index.html', element: <HomePage /> },
  { path: '/chat', element: <ChatPage /> },
  { path: '/chat.html', element: <ChatPage /> },
]);

export default function App() {
  return <RouterProvider router={router} />;
}
