function clearSession() {
  localStorage.removeItem('accessToken');
  localStorage.removeItem('userName');
  localStorage.removeItem('emailDomain');
  localStorage.removeItem('Startinitiate');
}

function getParams() {
  const urlSearchParams = new URLSearchParams(window.location.search);

  return Object.fromEntries(urlSearchParams.entries());
}

function buildState(params) {
  const paramCopy = { ...params };

  delete paramCopy.code;

  return btoa(JSON.stringify(paramCopy));
}

export function logIn() {
  const state = buildState(getParams());

  clearSession();

  // eslint-disable-next-line no-restricted-globals
  location.href = `${process.env.REACT_APP_AWS_COGNITO_INSTANCE_URL}/login?client_id=${process.env.REACT_APP_AWS_COGNITO_CLIENT_ID}&response_type=code&scope=email+openid&redirect_uri=${process.env.REACT_APP_AWS_COGNITO_REDIRECT_URI}&state=${state}`;
}

export function logOut() {
  console.log('LOGOUT');
  logIn();
}
